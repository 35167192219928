import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import Toaster from "../../../common/Toaster";
import Input from "../../../input";
import InputWithIcon from "../../../input/inputWithIcon";
import LinkText from "../../../text/linkText";
import RoundedBtn from "../../../button/roundedBtn";
import { register, getStatesHandler } from "../../../../actions/user/action";
import { addNewBrand as addBrand } from "../../../../actions/brand/action";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import "./style.scss";
import { navigate } from "gatsby";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";
import Select from "../../../select";
// import allCities from "./cities";
import { updateCurrentBrand as updateBrand } from "../../../../actions/brand/action";
import Title from "../../../text/title";

const RegistrationInfoComp = ({
  getStatesHandler,
  brandId,
  updateBrand
}) => {
  const [isMobile, setMobile] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [renderFormik, setRenderFormik] = useState(false);
  const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);

  const [formikValues, setFormikvalues] = useState({
    state: "",
    ein: "",
  });

  useEffect(async () => {
    let states = await getStatesHandler();
    if (states.error === false && states.responseData.length > 0) {
      setStates(
        states.responseData.map((e) => {
          return { value: e.abbreviation, label: e.name };
        })
      );
    }

    const localstorageUser = JSON.parse(
      localStorage.getItem("registeringUser")
    );

    setFormikvalues({
      
      state:
        localstorageUser && localstorageUser.state
          ? localstorageUser.state
          : "",
      ein: localstorageUser && localstorageUser.ein ? localstorageUser.ein : "",
    });

    // if(localstorageUser && localstorageUser.state){

      // let statesTemp =  states.responseData.map((e) => {
      //   return { value: e.abbreviation, label: e.name };
      // })

      // setCities(
      //   allCities[statesTemp.filter(function (option) {
      //     return option.value === localstorageUser.state;
      //   })[0].label].map((e) => {
      //     return { value: e, label: e };
      //   })
      // );
    // }

    setRenderFormik(true);

    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // const setCitiesHandler = (state) => {
  //   setCities(
  //     allCities[state.label].map((e) => {
  //       return { value: e, label: e };
  //     })
  //   );
  // };

  const navigateBack = () => {
    navigate("/onboarding/brand-type");
  };

  return (
    <Fragment>
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="onboarding row user-details brand-location">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image reg-mt-less">
          <div className="d-flex justify-content-center lottie-container left-flex">
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
            <Title className="hide-mobile" text="Company Website?" />
          </div>
          
          {/* <div className="d-flex justify-content-center image-subtext">
            Let's start
            <br />
            <span>{`It takes > 1min.`}</span>
          </div> */}
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
        <div className="brand-focus-heading hide-desktop">
            <Title text="Company Website?" />
          </div>
          {renderFormik && (
            <Formik
              initialValues={{ ...formikValues }}
              enableReinitialze={true}
              validate={(values) => {
                const errors = {};

                
                // if (!values.state) {
                //   errors.state = "Required";
                // }
                if (!values.website) {
                  errors.website = "Required";
                }
                return errors;
              }}
              onSubmit={async (values) => {
                if (!loading) {
                  setLoading(true);


                  let localstorageUserState = JSON.parse(localStorage.getItem("registeringUser"));//{};
                  // localstorageUserState.state = values.state;
                  localstorageUserState.website = values.website;
                  localStorage.setItem(
                    "registeringUser",
                    JSON.stringify(localstorageUserState)
                  );

                  var updateBrandResonse = await updateBrand(brandId, { 
                    website:values.website
                    // state:values.state,ein:values.ein 
                  },true);
                    if (updateBrandResonse && !updateBrandResonse.error) {
                      navigate("/onboarding/registration-successful");
                    } else {
                      setToastrMsg("Unable to add Info.");
                      doRefresh((prev) => prev + 1);
                    }
                    setLoading(false);
                  
                

                 
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                setFieldValue,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} className="form-register">
                  <>
                    <div className="form">
                     
                      <div className="row hide">
                       
                        <div className="ip-group col-12">
                          <Select
                            options={states}
                            placeholder="State"
                            defaultValue={states[0]}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("state", e.value);
                              // setFieldValue("city", "");
                              // setCitiesHandler(e);
                            }}
                            value={states.filter(function (option) {
                              return option.value === values.state;
                            })}
                          />
                          {errors.state && touched.state && (
                            <p className="error-class">
                              Please choose a State!
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row">
                       
                        <div className="ip-group col-12">
                          <InputWithIcon
                           icon="/images/common/company.svg"
                            name="website"
                            placeholder="Type your brand’s url"
                            value={values.website}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {errors.website && touched.website && (
                            <p className="error-class">
                              Please enter valid URL!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row back-buttons">
                      <div className="d-flex nav-icons">
                        <div className="margin-right-20-mobile">
                          <span
                            className="text-decoration-none"
                            onClick={() => navigateBack()}
                          >
                            <NavLeft type="button"/>
                            <span className="nav-text">Back</span>
                          </span>
                        </div>
                        <div>
                          <span
                            className="text-decoration-none"
                            onClick={() => handleSubmit()}
                          >
                            <span className="nav-text">Next</span>
                            <NavRight
                            type="submit"
                              blurred={
                                !values.name || !values.email || !values.phone
                                  ? 1
                                  : 0
                              }
                            />
                          </span>
                        </div>
                      </div>

                      {/* <div className="col-md-6 col-lg-6 col-sm-6 col-12"> </div>
                    <div className="col-md-6 col-lg-6 col-sm-6 col-12 step3-btn">
                      <RoundedBtn
                        background="#31FFEA"
                        color="#004E93"
                        tag="Continue"
                        type="submit"
                      />
                    </div> */}
                    </div>
                  </>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </Fragment>
  );
};
const BrandLocation = ({ brand }) => ({
  brandId: brand.brandId,
});

export default connect(BrandLocation, { getStatesHandler,updateBrand })(
  RegistrationInfoComp
);
