import React from "react";
import "./style.scss";

const Title = ({ text = "title", textAlign = "left",className="" }) => {
  return (
    <p className={`${className} title`} style={{ textAlign }}>
      {text}
    </p>
  );
};
export default Title;
