import React from "react";
import "./style.scss";

const LinkText = ({ text = "title", bold = false, color , className}) => {
  return (
    <p
      className={`link-text ${className}`}
      style={{ fontWeight: bold ? "500" : "300", color }}
    >
      {text}
    </p>
  );
};
export default LinkText;
