import React from "react";
import RegistrationInfoComp from "../../components/cards/onboardingCard/registration-info";
import "./style.scss";
// import StepThree from "../../components/cards/onboardingCard/stepThree";

const RegistrationInfo = () => {
  return (
    <div className="bg bg-img">
      <div className="mid-card card-4">
        <RegistrationInfoComp />
      </div>
   
    </div>
  );
};

export default RegistrationInfo;
