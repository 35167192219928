import React from "react";

const NavRight = ({...props}) => {
    return (
        <button className={`btn nav-btn ${props.blurred && props.blurred ===1 && 'blurred'}`} {...props}>
            <img 
             className="arrow-align-middle"
            src="/images/common/right-arrow-blue.svg" 
            />
        </button>
    );
}

export default NavRight;