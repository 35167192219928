import React from "react";

const NavLeft = ({...props}) => {
    return (
        <button className="btn nav-btn nav-left" {...props}>
            <img 
            className="arrow-align-middle"
            src="/images/common/left-arrow-blue.svg" />
        </button>
    );
}

export default NavLeft;