import React, { useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Toaster = (props) => {

    useEffect(()=>{
        if(props.refresh > 0){
            if(props.type==='error'){
                notifyError()
            }
            else{
                notifySuccess()
            }
        }
    },[props.refresh])

    const notifySuccess = () => toast.success(props.message,{closeOnClick: true});
    const notifyError = () => toast.error(props.message,{closeOnClick: true});

    return (
        <>
            <ToastContainer 
            closeOnClick
            closeButton={true}
            />
        </>
    );
}

export default Toaster;