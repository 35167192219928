import React from "react";
import "./style.scss";

const InputWithIcon = ({
  placeholder,
  icon,
  classes,
  type = "text",
  ...props
}) => {
  const className = `form-control ip ${classes}`;

  return (
    <input
      type={type}
      className={className}
      placeholder={placeholder}
      style={{
        background: `url(${icon}) no-repeat scroll 7px 7px`,
      }}
      {...props}
    />
  );
};

export default InputWithIcon;
